///////////////////////////////
//AWS Tools Styles
//////////////////////////////

div.content_block {

    display: flex;
    flex-direction: column;

    span.contentText {
        padding: 20px 0;
    }

}