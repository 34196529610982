//////////////////////
//Variables
/////////////////////

$white: #ffffff;
$black: #000000;
$gray-light:  #f9f9f9;
$blue-light:  #a1b8ce;
$blue:        #0d3ecc;
$blue-dark:   #28364e;
$yellow:    #ffd061;

$header-background-color: $yellow;
$background-color: $blue-light;
$footer-background-color: $blue-dark;

$border: 1px solid $black;
$border-radius: 4px;

$link-color: $yellow;