////////////////////////
//Footer Styles
///////////////////////

footer {
    border-top: 2px solid $black;
    div.footer_content {
        display: flex;
        justify-content: center;
        padding: 40px 20px;
        align-items: flex-end;
        background: $footer-background-color;
        h3 {
            color: $white;
        }
        
    }
}
