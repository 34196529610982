//////////////////////
//Mixins
/////////////////////

@mixin vertical-list {
    display: flex;
    align-items: center;
    flex-direction: column;
}

// source: https://www.section.io/engineering-education/facilitating-css-media-queries-using-scss-mixins/#:~:text=SCSS%20mixins%20give%20the%20ability,and%20break%20up%20your%20code.
@mixin devices ($breakpoint) {
    @if $breakpoint == desktops {
      @media only screen and (min-width: 1024px) {
        @content;
      }
    }
  
    @if $breakpoint == laptop_or_mobiles {
      @media only screen and (max-width: 1920px) {
        @content;
      }
    }
  
    // tablet and mobile phone
    @if $breakpoint == mobile_devices {
      @media only screen and (max-width:1023px) {
        @content;
      }
    }
    // tablet only
    @if $breakpoint == tablets {
      @media only screen and (min-width: 866px) and (max-width:1023px) {
        @content;
      }
    }
    //desktop and tablet
    @if $breakpoint == not_mobiles {
      @media only screen and (min-width: 1023px) {
        @content;
      }
    }
    // mobile phone only
    @if $breakpoint == iphones {
      @media only screen and (max-width: 865px) {
        @content;
      }
    }
}